//Check it and remove

h3, .h3 {
  font-size: 15px;
}

.rel {
  position: relative;
}

.text-muted {
  margin: 20px 0;
}

.w100 {
  width: 100%;
}

.pad0 {
  padding: 0 !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.pb50 {
  padding-bottom: 50px;
}

div.form-control,
textarea.form-control {
  min-height: 85px;
  height: auto;
}

.minw160 {
  min-width: 160px;
}

.photo {
  padding-right: 0;
}

.doctorsoncall_user_register_step p.input-files {
  line-height: 19px;
}

.trasparent-a a {
  background-color: transparent;
  color: #fff;
}

td.name-colomn img {
  margin-right: 15px;
}

.absrb {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.mbt10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group {
  min-height: 28px;
}

.nopr {
  padding-right: 0px;
}

/* DEFAULT FORM STYLES */

form label {
  display: block;
  font-weight: normal;
}

form select.form-control {
  width: 100%;
  height: 28px;
  padding: 3px 12px;
}

form select[multiple="multiple"].form-control {
  height: 80px;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number] {
  height: 28px;
  width: 100%;
}

label[for="doctorsoncall_backendbundle_record_other"] {
  float: none;
  display: inline-block;
}

form textarea {
  width: 100%;
  height: 100px;
  max-width: 100%;
}

form input[type='file'] {
  float: left;
}

form input + label {
  display: inline-block;
  float: none;
  width: auto;
  margin: 0 10px 0 0;
}

.form-group.date .row {
  margin-left: -10px;
  margin-right: -10px;
}

.form-group.date .row > * {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

input[type=number] {
  padding-top: 4px;
}

/* CHARTS */

.child100 > div {
  width: 100% !important;
}

.tab-pane.showOnStart {
  display: block !important;
  opacity: 0;
}

/* MESSAGES ON VIDEO */

.videoMessage1, .videoMessage2, .videoMessage3 {
  text-align: center;
  background-color: #009fd5;
  color: #fff;

  font-size: 15px;
  text-transform: uppercase;
  padding-top: 5px;
}

.videoMessage2, .videoMessage3 {
  display: none;
}

/* FILES */

ul.files {
  padding: 0px;
}

ul.files li {
  list-style: none;
}

ul.files i {
  color: red;
  cursor: pointer;
}

input[type=file] {
  display: none;
}

/* FILES */

/* MAIN REGISTRATION */

.not-r-padding {
  padding-right: 0 !important;
}

.main-register-block .image,
.main-register-block .text {
  position: relative;
  padding: 30px 0;
}

.main-register-block .image p {
  text-transform: uppercase;
}

.main-register-block .text a {
  line-height: 21px;
  margin-top: 23px;
  padding: 5px 27px;
}

.main-register-block .text a:hover {
  background: #006396;
}

.main-register-block {
  margin: 0 auto;
  width: 33%;
  max-width: 100%;
}

.main-register-block .gray-bg {
  width: 500%;
  margin: 0 -200%;
}

/* MAIN REGISTRATION */

/* REGISTRATION POPUP */

.popup-registration {
  background-image: url("../images/opacityBlack.png");
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0px;
  left: 0px;
}

.popup-registration-window {
  width: 650px;
  height: 330px;
  background-color: #fff;
  margin: 210px auto 0;
  text-align: center;
}

.popup-registration-window img {
  padding-top: 60px;
}

.popup-registration-window p {
  padding-top: 50px;
  color: #646472;
  font-size: 14px;
}

.popup-registration-window a {
  background-color: #0080b3;
  text-transform: uppercase;
  font-size: 15px;
  margin-top: 38px;

  padding: 6px 80px;
  height: 35px;
  border: 0;
}

/* REGISTRATION POPUP */

/* MEDICAL HISTORY  */

/*#doctorsoncall_backendbundle_medicalhistory div.input-files{
    line-height: 19px;
    margin-right: 10px;
    }*/

#doctorsoncall_backendbundle_medicalhistory .btn.input-files,
form[name=doctorsoncall_backendbundle_medicalhistory]  .btn.input-files {
  width: auto;
  display: inline-block;
  float: left;
  margin-right: 5px;
}

#doctorsoncall_backendbundle_medicalhistory button {
  background-color: #006396;
  border-color: #006396;
  text-transform: uppercase;
}

#doctorsoncall_backendbundle_medicalhistory button:hover {
  background-color: #009FD5;
  border-color: #009FD5;
}

/* MEDICAL HISTORY  */

/* ERRORS */
.alert.alert-warning ul li {
  list-style: none !important;
}

.container > .alert:first-child {
  margin-top: 20px;
}

/* ERRORS */

/* FRONT PAGE */
.plr30 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
}

.main-register-block .image img {
  width: 16%;
  margin-top: 9px;
}

.fos_user_security_login .image img {
  width: 3%;
}

.showAlways {
  display: block !important;
}

.doctorsoncall_frontend_doctor_profile div.image img, .doctorsoncall_frontend_patient_profile div.image img {
  width: 4%;
}

.clr,
.clear {
  clear: both;
  margin: 0 !important;
  padding: 0 !important;
}

/*VALIDATION*/
.pwd-error {
  color: red;
  font-size: 10px;
  text-align: right;
}

.input-files {
  float: left;
  margin-right: 10px;
}

.input-files.btn {
  width: 220px;
  max-width: 100%;
}

/*TIMER*/

//.textDiv_Seconds {
//  display: none;
//}
//
//.timer-abs {
//  position: absolute;
//  top: 29px;
//  left: 0px;
//  text-align: center;
//  width: 100%;
//}

/*DOCTOR POPUP*/
.doctor-popup a {
  color: white;
}

.doctor-popup img {
  margin-right: 10px;
  width: 55px;
}

.popup-close {
  position: absolute;
  top: 28px;
  right: 20px;
  height: 21px;
  width: 20px;
  background: url("../images/popup-close.fw.png") center center no-repeat;
  cursor: pointer;
}

.line-popup {
  overflow: hidden;
  padding: 15px 0px;
}

label span.filename {
  margin-left: 16px;
}

span.filename {
  color: #428bca;
  padding: 2px;
}

label[for=doctorsoncall_backendbundle_medicalhistory_file] {
  display: inline-block;
}

span.filename.bad p {
  background: #FDCECE;
  border-radius: 5px;
  margin-left: 0px;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
}

form[name=doctorsoncall_backendbundle_medicalhistory] span.filename.bad p {
  margin-left: 5px;
  display: inline;
  margin-top: 0px;
}

.deleteFile {
  float: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../images/icon_delete.png') right 5px no-repeat;
  margin-bottom: -4px;
}

.halfbuttons .input-files {
  width: 50%;
}

#doctorsoncall_backendbundle_medicalhistory_Add {
  padding-right: 25px;
  padding-left: 25px;
}

#doctorsoncall_backendbundle_healthtracker .form-group {
  margin-bottom: 15px;
}

form[name=doctorsoncall_backendbundle_session] button {
  margin-top: 10px;
}

label.required + label.error {
  position: inherit;
}

input[type="text"] + label.error, input[type="password"] + label.error {
  right: -6px;
}

div.legend ul {
  list-style: none;
}

div.legend ul li span {
  margin-right: 6px;
}

div.captcha img {
  margin-bottom: 5px;
  margin-right: 5px;
  float: left;
}