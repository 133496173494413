//vendor
@import "bootstrap";
@import "bootstrap-datepicker";
@import "bootstrap-select";
@import "../../web/vendor/components-font-awesome/scss/font-awesome.scss";
@import "bourbon/bourbon";


@import "mixins";
@import "old-styles";
@import "settings";


.main-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin-bottom: -$footer-height;
  position: relative;
  z-index: 1;
}

label {
  font-weight: 400;
  &.error {
    position: absolute !important;
    top: 101%;
    left: 15px;
    font-size: 11px !important;
    line-height: 1 !important;
    color: red !important;
    font-weight: 400 !important;
    height: auto !important;
    z-index: 3;
  }
}
label.required + label.error {
  left: 0;
}
input[type=file] + label.error {
  margin-top: 0 !important;
}
.form-group {
  &.date {
    label {
      &.error {
        left: 10px;
      }
    }
  }
}
//File upload block
.remove-image-icon {
  color: $red;
  margin-left: 5px;
  font-size: 18px;
  &:hover {
    color: scale_color($red, $lightness: 20%);
  }
}
.MultiFile-remove {
  float: right;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #b0b0b0;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &.error {
    border-color: $red;
  }
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }

  &::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  &:-ms-input-placeholder, &::-webkit-input-placeholder {
    color: #999;
  }

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &[disabled], &[readonly] {
    background-color: #eee;
    opacity: 1;
  }
}
.form-group {
  position: relative;
}
.has-error {
  .form-control {
    border-color: red;
  }
}

html {
  height: 100%;
  background: #fff;
}

body {
  min-width: 320px;
  background: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: $dark-blue;
  margin: 0;
  height: 100%;
}

* {
  outline: none !important;
}

.imp-info {
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  padding: 50px 0;
}

//Buttons
.button {
  cursor: pointer;
  background: $light-blue;
  color: #fff;
  font-size: 15px;
  line-height: 32px;
  height: 32px;
  min-width: 170px;
  text-align: center;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  transition: $button-transition;
  padding: 0 10px;
  vertical-align: middle;
  overflow: hidden;
  &.w100 {
    min-width: 50px;
    width: 100%;
  }
  &.small {
    min-width: 50px;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
    padding: 0 15px;
  }
  &.min {
    min-width: 10px;
    height: 27px;
    line-height: 27px;
    font-size: 14px;
    padding: 0 8px;
  }
  &.big {
    line-height: 52px;
    height: 52px;
    font-size: 18px;
    text-transform: uppercase;
  }
  &.green {
    background: $green;
    &:hover,
    &:active,
    &:focus {
      background: scale_color($green, $lightness: -50%);
    }
  }
  &.red {
    background: $red;
    &:hover,
    &:active,
    &:focus {
      background: scale_color($red, $lightness: -50%);
    }
  }
  &.action {
    .icon {
      background: $dark-blue url("../images/action-icon.png") no-repeat center;
      width: 52px;
      height: 52px;
      float: right;
      margin-right: -10px;
    }
  }
  &.disabled {
    opacity: .6;
    background: $gray;
    cursor: default;
    &:hover {
      background: $gray !important;
    }
  }
  &:hover,
  &:active,
  &:focus {
    color: #fff;
    text-decoration: none;
    background: $dark-blue;
    transition: $button-transition;
  }
}

.alert-info {
  line-height: 1.2;
  display: inline-block;
  margin: 5px 0 10px 0;
  background: none;
  color: $light-blue;
  font-weight: 700;
}

.break-words {
  word-break: break-all;
  word-break: break-word;
}

.break-xs {
  @include media_max($xs-break) {
    white-space: normal !important;
  }
}

.bold {
  font-weight: 700;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0 !important;
}

.blue {
  color: $light-blue;
}

.gray-bg {
  background: $gray-bg;
}

.dark-blue-bg {
  background: $dark-blue;
  color: #fff;
}

.light-blue-bg {
  background: scale_color($dark-blue, $lightness: 98%);
}

.img-w100 {
  width: 100%;
  height: auto;
}

//input radio
input[type=radio] {
  max-width: 1px;
  max-height: 1px;
  opacity: 0;
  float: left;
  margin: 0;
  & + label {
    background: url('../images/radio.png') left no-repeat;
    padding: 0 20px 0 40px !important;
    margin-bottom: 4px;
    line-height: 28px !important;
    display: inline-block !important;
    &.error {
      left: 0;
    }
    &:last-of-type {
      padding-right: 0 !important;
    }
  }
  &:checked + label {
    background: url('../images/radio-active.png') left no-repeat;
  }
}

//modal
.modal-content {
  overflow: hidden;
  .modal-header {
    background: $dark-blue;
    color: #fff;
    .close {
      color: #fff;
      opacity: 1;
      &:hover,
      &:focus {
        opacity: .6;
      }
    }
  }
  .modal-footer {
    border-top: 1px solid $dark-blue;
  }
  label.error,
  .has-error {
    position: absolute;
    top: 53px;
    left: 0;
    font-size: 11px;
    //color: red;
    line-height: 1;
  }
}

// Header
header {
  position: relative;
  z-index: 10;
  padding: 30px 0 22px 0;
  font-size: 10px;
  text-align: center;
  .line {
    background: $dark-blue;
    height: 4px;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 96px;
  }
  .logo {
    float: left;
    position: relative;
    z-index: 3;
    background: #fff;
    padding: 0 18px;
    img {
      display: block;
    }

    @include media_max($sm-break) {
      padding: 0 10px;
    }
  }
  #menu-block {
    border: 0;
    background: none !important;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    top: 9px;
    .navbar-toggle {
      background: none;
      margin: 0 15px 0 0;
    }
    #navbarCollapse {
      padding: 0;
      float: right;
      border: 0;
      .navbar-nav {
        @include media_max($xs-break) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      li {
        a {
          color: $dark-blue;
          font-size: 1.5em;
          text-transform: uppercase;
          padding-top: 27px;
          line-height: 1;
          height: 61px;
          margin: 0 8px;
          background: none;
          position: relative;
          .tooltip {
            text-transform: none;
          }
          .counter-icon {
            position: absolute;
            z-index: 2;
            right: -5px;
            top: 13px;
            background: $dark-blue;
            color: #fff;
            border-radius: 50%;
            font-size: .8em;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            box-shadow: #999 0 0 2px 0;
            .plus {
              position: absolute;
              top: -1.5px;
              right: 0;
              @include media_max($xs-break) {
                top: -1px;
              }
            }
            &.red {
              background: $red;
            }
            @include media_max($md-break) {
              right: -10px;
              top: 12px;
            }
            @include media_max($sm-break) {
              right: -7px;
              top: 12px;
              font-size: .9em;
              width: 18px;
              height: 18px;
              line-height: 18px;
            }
            @include media_max($xs-break) {
              right: -19px;
              top: -8px;
              font-size: .8em;
              width: 20px;
              height: 20px;
              line-height: 20px;
            }
          }
          &[href='/'] {
            background: url("../images/icon-home.png") no-repeat center top;
          }
          &[href='/login'] {
            background: url("../images/icon-login.png") no-repeat center top;
          }
          &[href='/registration'] {
            background: url("../images/icon-join-now.png") no-repeat center top;
          }
          &:hover {
            border-bottom: 4px solid $light-blue;
          }
          @include media_max($md-break) {
            margin: 0 5px;
            font-size: 1.4em;
            padding-right: 7px;
            padding-left: 7px;
          }
          @include media_max($sm-break) {
            margin: 0 1px;
            font-size: 1.05em;
          }
          @include media_max($xs-break) {
            background: none !important;
            height: auto;
            font-size: 1.5em;
            display: inline-block;
            padding: 5px 0;
            margin-bottom: 5px;
          }
        }
        &.active {
          a {
            border-bottom: 4px solid $light-blue;
          }
        }
      }
      .dropdown-menu {
        li {
          padding: 10px;
          a {
            font-size: 15px;
            padding: 0;
            height: auto;
            border: 0;
            span {
              margin-right: 5px;
              width: 18px;
              display: inline-block;
              @include media_max($xs-break) {
                margin-left: -26px;
              }
            }
            &:hover {
              color: $light-blue;
            }
          }
          @include media_max($xs-break) {
            padding: 0;
          }
        }
        @include media_max($xs-break) {
          text-align: center;
        }
      }
      .prescription-menu {
        .dropdown-menu {
          left: 25px;
          min-width: 100px;
          @include media_max($xs-break) {
            left: auto;
          }
        }
      }
      @include media_max($xs-break) {
        float: none;
        margin-top: 80px;
      }
    }
  }
}

//Footer
.footer-push {
  @include clearfix();
  height: $footer-height;
}
footer {
  position: relative;
  z-index: 2;
  margin-top: 0;
  background: $dark-blue;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  padding: 100px 0 25px 0;
  text-align: center;
  .first-line {
    margin-bottom: 55px;
    .menu {
      text-align: left;
      ul {
        list-style: none;
        padding: 0;
        li {
          padding: 6px 0;
          a {
            font-size: 1.4em;
            line-height: 1.2;
            text-transform: uppercase;
            color: #fff;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
      .social {
        li {
          padding: 0;
          a {
            display: inline-block;
            padding: 5px;
            span {
              display: block;
              width: 16px;
              height: 16px;
              background: url("../images/social-icons.png") no-repeat;
              &.facebook {
                background-position: 0 0;
                &:hover {
                  background-position: 0 -16px;
                }
              }
              &.twitter {
                background-position: -16px 0;
                &:hover {
                  background-position: -16px -16px;
                }
              }
              &.linked-in {
                background-position: -32px 0;
                &:hover {
                  background-position: -32px -16px;
                }
              }
            }
          }
          @include media_max($xs-break) {
            display: inline-block;
          }
        }
        @include media_max($xs-break) {
          padding: 0;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      @include media_max($xs-break) {
        text-align: center;
      }
    }
    @include media_max($sm-break) {
      margin-bottom: 30px;
    }
    @include media_max($xs-break) {
      margin-bottom: 20px;
    }
  }
  .copyright {
    font-size: 1.4em;
    line-height: 1.3;
    .logo {
      width: 60px;
      height: 30px;
      margin: 0 auto 15px auto;
      background: url("../images/logo.png") no-repeat center top;
      background-size: 60px auto;
    }
  }
  .payment-icons {
    margin-top: 10px;
    img {
      display: inline-block;
      margin: 0 3px;
    }
  }
  @include media_max($sm-break) {
    padding-top: 60px;
  }
  @include media_max($xs-break) {
    padding-top: 30px;
  }
}

// FORMS
$max-width: 700px;
.table-view {
  font-size: 10px;
  .title-block {
    padding: 15px 0;
    line-height: 70px;
    font-size: 1.8em;
    color: $dark-blue;
    .container {
      position: relative;
      .icon-block {
        float: left;
        width: 32px;
        height: 70px;
        background-size: contain;
        margin-right: 20px;
        @include media_max($xs-break) {
          width: 22px;
        }
      }
      .toggle-arrow {
        background: url("../images/arrowOpen.png") no-repeat right top;
        width: 32px;
        height: 18px;
        position: absolute;
        right: 15px;
        top: 50%;
        margin-top: -9px;
      }
      &.centering-title {
        max-width: $max-width;
        margin-top: 15px;
      }
      .small-xs {
        @include media_max($xs-break) {
          font-size: 12px;
        }
      }
    }
    &.toggle-title-block {
      cursor: pointer;
      &:hover {
        background: scale_color(#fff, $lightness: -2%);
      }
    }
    &.small {
      .container {
        max-width: 650px;
      }
    }
    @include media_max($xs-break) {
      font-size: 1.4em;
      padding: 0;
    }
  }
  .zebra-rows {
    .form-group:not(.no-zebra) {
      margin: 0;
      padding: 6px 0;
      //&:nth-of-type(2n+1) {
      //background: scale_color($light_blue, $lightness: 95%);
      border-bottom: 1px dotted #c5c5c5;
      //}
      &:last-of-type,
      &.last-block,
      &.no-border {
        border-bottom: 0;
      }
      &.border-top {
        border-top: 1px dotted #c5c5c5;
      }
      input[type=text],
      input[type=email],
      input[type=password],
      select,
      textarea,
      .button.min,
      .button.small {
        margin-top: 5px;
      }
      .row-label {
        padding-top: 0;
      }
      &.no-border {
        border: 0;
      }

    }
    .more-padding {
      //padding: 10px 0;
    }
  }
  .data-profile {
    @include clearfix();
    max-width: $max-width;
    margin: 0 auto;
    padding: 30px 0 30px 0;
    font-size: 1.5em;
    line-height: 28px;
    .show-on-edit {
      display: none;
    }
    .avatar-register {
      padding: 0;
      //margin: 0 5px 10px -15px;
      margin: 5px 0 10px 0;
      img {
        width: 100%;
      }
      button {
        @extend .button;
        font-size: 16px;
        min-width: 0;
        width: 100%;
        margin: 0;
        border-radius: 0 0 4px 4px;
      }
      @include media_max($xs-break) {
        margin: 0 auto 20px auto;
        max-width: 200px;
      }
    }
    label {
      line-height: 1.2;
      padding: 0;
      margin: 0;
      display: table-cell;
      vertical-align: middle;
      height: 28px;
      //&.error {
      //  position: relative;
      //  display: block;
      //  font-size: 11px;
      //  line-height: 1;
      //  top: 0;
      //  left: 0;
      //  right: auto;
      //  margin-bottom: 5px;
      //  height: auto;
      //}
    }
    .form-group {
      margin-bottom: 10px;
      .row-label {
        line-height: 1;
        padding-top: 6px;
        padding-right: 0;
        font-weight: normal;
        label {
          line-height: 1.2;
          padding: 0;
          margin: 0;
          display: table-cell;
          vertical-align: middle;
          height: 28px;
          @include media_max($xs-break) {
            font-size: 12px;
          }
        }
      }
      .row-text {
        line-height: 28px;
        @include media_max($xs-break) {
          font-size: 12px;
        }
      }
      input[type=radio] {
        & + label {
          padding: 0 0 0 40px !important;
          margin: 5px 20px 5px 0;
          text-align: left;
          &:last-of-type {
            margin-right: 0;
          }
          @include media_max($xs-break) {
            padding: 0 0 0 35px !important;
            margin: 5px 10px 5px 0;
          }
        }
      }
      .row-label-block {
        line-height: 1;
        padding: 6px 15px 0 15px;
        //font-weight: bold;
      }
      input[type=text],
      input[type=email],
      input[type=password],
      select,
      textarea,
      .button.min {
        margin-bottom: 5px;
        resize: none;
        @include media_max($xs-break) {
          font-size: 12px;
        }
        &.delete-tag {
          @include media_max($xs-break) {
            font-size: 10px;
          }
        }
      }

      .date-line {
        select {
          @include media_max($xs-break) {
            //max-width: 100px;
          }
        }
      }
      .form-control {
        @include media_max($xs-break) {
          font-size: 12px;
        }
      }
      &.session-buttons {
        text-align: center;
        margin-top: 30px;
        a.button {
          margin-bottom: 15px;
          @include media_max($xs-break) {
            float: none !important;
          }
        }
      }
    }
    .clearImage {
      width: 30px;
      height: 30px;
      background: $light-blue url('../images/avatar-remove.png') center no-repeat;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0 0 0 4px;
      cursor: pointer;
      transition: $button-transition;
      &:hover {
        background: $dark-blue url('../images/avatar-remove.png') center no-repeat;
        transition: $button-transition;
      }
    }
    .file-links {
      font-size: 14px;
      line-height: 1.2;
      display: inline-block;
    }
    .btn-line {
      @include clearfix();
      border-top: 1px solid $dark-blue;
      padding-top: 20px;
      //margin-top: 2px;
      text-align: center;
      .button {
        &:first-of-type {
          float: right;
          margin-bottom: 15px;
        }
        &:last-of-type {
          float: left;
        }
        @include media_max($xxs-break) {
          float: none !important;
        }
      }
      .hide-on-edit {
        .button {
          float: right;
        }
      }
    }
    // Languages
    #language-fields-list {
      padding: 0;
      li {
        list-style: none;
        .form-group {
          padding: 0;
        }
      }
      margin: 0;
    }
    #add_language_link {
      margin: 5px 0;
    }

    //Tags
    #tags-fields-list {
      padding-left: 0;
      li {
        list-style: none;
        input {
          float: left;
          width: 56%;
          margin-right: 3%;
          display: block;
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }
    #add_tag_link {
      margin: -10px 0 5px 0;
    }

    //Qualifications
    .qualifications {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 20px;
      }
    }
    .qualifications-other {
      padding: 0;
      list-style: none;
      li {
        margin-bottom: 20px;
        .alert-info {
          font-size: 11px;
          text-align: right;
        }
      }
    }
    #add_qualification_link,
    #add_qualification_other_link {
      margin-top: -15px;
    }
    @include media_max($xs-break) {
      font-size: 1.3em;
      padding: 10px 0 30px 0;
    }
    &.editing {
      .show-on-edit {
        display: block;
      }
      .hide-on-edit,
      label.hide-on-edit {
        display: none;
      }
      .row-label {
        label {
          height: 38px;
        }
      }
      .row-text {
        line-height: 38px;
      }
    }
    &.hidden-profile {
      display: none;
    }
  }
  &.blue-bg {
    background: scale_color($dark-blue, $lightness: 98%);
    .toggle-title-block {
      &:hover {
        background: scale_color($dark-blue, $lightness: 96%);
      }
    }
  }
  &.personal-information {
    .icon-block {
      background: url("../images/personalinfo.png") no-repeat right center;
    }
    .text {
      padding: 0;
    }
    input[type=radio] {
      & + label {
        margin-top: 5px;
        @include media_max($xs-break) {
          padding: 0 0 0 35px !important;
          &:last-of-type {
            margin-left: 15px;
          }
        }
      }
    }
  }
  &.call-tokens,
  &.banking-details {
    .toggle-title-block {
      .icon-block {
        background: url("../images/creditcard.png") no-repeat right center;
        background-size: contain;
        width: 42px;
        margin-left: -10px;
        @include media_max($xs-break) {
          width: 32px;
        }
      }
    }
    .data-profile {
      .text {
        font-size: 1.3em;
        line-height: 1;
        margin-bottom: 17px;
        @include media_max($xs-break) {
          text-align: center;
        }
      }
    }
  }
  &.health-history {
    .icon-block {
      background: url("../images/declaration.png") no-repeat right center;
    }
    .main-question {
      font-weight: 700;
      line-height: 1.25;
      color: $dark-blue;
      margin-bottom: 15px;
      padding: 0 15px;
      @include media_max($xs-break) {
        font-size: 1em;
      }
    }
  }
  &.existing-doctor {
    .icon-block {
      background: url("../images/existing_doctor.png") no-repeat right center;
    }
  }
  &.medication {
    .icon-block {
      background: url("../images/medication.png") no-repeat right center;
    }
  }
  &.pharmacy-details {
    .icon-block {
      background: url("../images/pharmacy.png") no-repeat right center;
    }
  }
  &.professional-details {
    .icon-block {
      background: url("../images/professionaldetails.png") no-repeat right center;
    }
  }
  &.doctor-services {
    .icon-block {
      margin-top: -2px;
      background: url("../images/other.png") no-repeat right center;
    }
  }
  &.professional-indemnity {
    .icon-block {
      background: url("../images/indemnity.png") no-repeat right center;
    }
  }
  &.qualifications {
    .icon-block {
      background: url("../images/qualification.png") no-repeat right center;
    }
    .form-group {
      .input-files {
        float: right;
        margin: 5px 0 0 0;
      }
    }
  }
  &.fitness-to-practice {
    .icon-block {
      background: url("../images/fitnessToPractive.png") no-repeat right center;
    }
  }
  &.declaration {
    .icon-block {
      background: url("../images/declaration.png") no-repeat right center;
    }
    .form-group {
      .input-files {
        float: right;
        margin: 5px 0 0 0;
      }
    }
  }
  &.medical-documentation {
    .icon-block {
      background: url("../images/medicalDoc.png") no-repeat right center;
    }
    .sep-line {
      border-bottom: 1px solid $dark-blue;
      padding-bottom: 10px;
      margin-bottom: 20px;
    }
    input[type=file] + button {
      @extend .button;
      margin: 10px 0;
    }
  }
  &.login-details {
    .icon-block {
      background: url("../images/loginIcon.png") no-repeat right center;
    }
    .data-profile {
      max-width: 550px;
      .captcha {
        a {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .remember-line {
      label {
        cursor: pointer;
        input[type=checkbox] {
          float: left;
          margin: 2px 10px 5px 0;
          width: 16px;
          height: 16px;
        }
      }
    }
    .forgot-line {
      padding-top: 10px;
      a {
        font-size: .8em;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  &.patient-block {
    .icon-block {
      background: url("../images/regPatientIcon.png") no-repeat right center;
    }
  }
  &.doctor-block {
    .icon-block {
      background: url("../images/regDocIcon.png") no-repeat right center;
    }
  }
  &.care-free-block {
    .icon-block {
      background: url("../images/regPatientIcon.png") no-repeat right center;
    }
  }
  &.corporate-block {
    .icon-block {
      background: url("../images/regPatientIcon.png") no-repeat right center;
    }
  }
  &.pharmacy-block {
    .icon-block {
      background: url("../images/medication.png") no-repeat center;
      background-size: 70% !important;
    }
  }
  &.open-profile {
    .toggle-title-block {
      .toggle-arrow {
        background: url("../images/arrowClose.png") no-repeat right top;
      }
    }
    .hidden-profile {
      display: block;
    }
  }
  .hide-profile {
    display: none;
  }
}

//Registartion steps
.regSteps {
  background: #0080B3;
  padding-top: 54px;
  padding-bottom: 22px;
  .row {
    margin-left: 25px;
    margin-right: 25px;
    .step-line {
      border-top: 1px solid #80C0D9;
      z-index: 1;
      &.active {
        border-top: 1px solid white;
      }
    }
  }
  .stepName {
    color: #80C0D9;
    margin-left: -15px;
    margin-right: -25px;
    margin-top: -37px;
  }
  .dotCont {
    float: left;
    position: relative;
    margin-left: -5px;
    margin-right: -5px;
    text-align: center;
    .dot {
      background: #80C0D9;
      width: 10px;
      height: 10px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      position: absolute;
      z-index: 2;
      top: -5px;
      left: 0;
    }
    &.active {
      .dot {
        background: white;
      }
      .stepName {
        color: white;
      }
    }
  }
}

//Registration blocks
$max-width: 500px;
$max-width-steps: 600px;
.register-block {
  .table-view {
    font-size: 10px;
    .title-block {
      font-size: 15px;
      .icon-block {
        @include media_max($xs-break) {
          display: inline-block;
          float: none;
          height: 30px;
          line-height: 30px;
          margin-right: 10px;
          margin-left: -22px;
          background-position: bottom;
        }
      }
      .button-block {
        float: right;
        margin-top: -2px;
        a {
          line-height: 34px;
        }
        @include media_max($xs-break) {
          margin: 15px 0 0 0;
          text-align: center;
          float: none;
        }
      }
      @include media_max($xs-break) {
        padding: 25px 0 35px 0;
        font-size: 2em;
        line-height: 30px;
        text-align: center;
      }
    }
    .data-profile {
      max-width: $max-width;
      .form-group {
        @include clearfix();
        .row-label {
          padding: 0 15px;
          text-align: right;
          label {
            display: inline-block;
            height: auto;
            line-height: 28px;
          }
        }
        &.captcha {
          a {
            right: auto;
            left: 152px;
          }
          #fos_user_registration_form_captcha,
          #register_pharmacy_captcha {
            @extend .form-control;
            height: 28px;
          }
        }
      }
    }
  }
  &.register-form {
    .table-view {
      .title-block {
        text-align: left;
        padding-bottom: 0;
        height: 70px;
        font-size: 1.7em;
        .container {
          max-width: $max-width;
          .icon-block {
            margin: 0 30px -25px -60px;
            @include media_max($xs-break) {
              margin: 0 10px -5px 0;
            }
          }
          .three-lines {
            display: inline-block;
            line-height: 1.5;
            margin: 20px 0;
          }
        }
        @include media_max($xs-break) {
          text-align: center !important;
          font-size: 1.6em;
        }
      }
    }
    .register-btn-line {
      padding: 40px 0 30px 0;
      .container {
        max-width: $max-width;
        .button {
          @include media_max($xs-break){
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  &.steps-form {
    .table-view {
      .title-block {
        text-align: left;
        padding-bottom: 0;
        height: 70px;
        font-size: 1.7em;
        .container {
          max-width: $max-width-steps;
        }
      }
      .data-profile {
        max-width: $max-width-steps;
        .form-group {
          .row-label {
            &.two-line {
              label {
                line-height: 1;
              }
            }
            &.two-lines-xs {
              label {
                @include media_max($xs-break) {
                  line-height: 1;
                }
              }
            }
          }
          input[type=radio] {
            & + label {
              margin-top: 0;
              margin-left: 0;
              &:first-of-type {
                @include media_max($xs-break) {
                  margin-right: 5px;
                }
              }
            }
          }
          &.row {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }
    .register-btn-line {
      .container {
        max-width: $max-width-steps;
      }
    }
    #add_qualification_link,
    #add_otherQualification_link {
      margin-top: 0;
    }
  }
}
.reg-step-4 {
  .form-group {
    margin-bottom: 20px !important;
  }
  .two-line {
    label {
      line-height: 1.2 !important;
    }
    .help-info {
      margin-top: 3px;
      display: inline-block;
    }
  }
}

.register-form {
  label {
    &.error {
      margin-top: -5px;
    }
  }
}

@import 'marketing-pages';

//Header banner
.header-bg {
  height: 150px;
  max-width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../images/headerBg.jpg");
  @include media_max($md-break) {
    min-height: 150px;
  }
  @include media_max($xs-break) {
    min-height: 150px;
  }
}

//Page title
.title-cont {
  /*width: 1250px;*/
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  height: 150px;
  .page-title {
    font-size: 15px;
    color: $light-blue;
    background: rgba(#fff, .9);
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    right: 15px;
    bottom: 38px;
    @include media_max($xs-break) {
      right: 0;
      display: block;
      width: 100%;
    }
  }
  .doctor_activity {
    position: absolute;
    left: 15px;
    bottom: 38px;
    div {
      font-size: 15px;
      color: $light-blue;
      background: #fff;
      padding: 0 10px;
      text-align: left;
      text-transform: uppercase;
    }
  }
  @include media_max($md-break) {
    min-height: 150px;
  }
}

//Content default block
.content-block {
  padding: 80px 0;
  font-size: 16px;
  line-height: 1.8;
  .block-text {
    text-align: left;
  }
  ul {
    font-size: 18px;
    padding-left: 23px;
    list-style-image: url("../images/list.png");
    text-align: left;
  }
  .button {
    margin-top: 20px;
  }
  &.small-block {
    .container {
      max-width: 800px;
    }
  }
  &.small-block2 {
    .container {
      max-width: 900px;
    }
  }
  @include media_max($md-break) {
    font-size: 14px;
  }
  @include media_max($sm-break) {
    padding: 50px 0;
  }
  @include media_max($xs-break) {
    padding: 35px 0;
    text-align: center;
  }
}

.main-cont {
  padding: 50px 0;
}

.page-text-title {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 20px;
  line-height: 1.2;
  @include media_max($md-break) {
    font-size: 20px;
  }
  @include media_max($xs-break) {
    font-size: 18px;
    text-align: center;
  }
}



// Default page with full width and height table
.table-page {
  padding: 0;
  .table-responsive {
    border: 0;
    margin-bottom: 0;
  }
  table {
    margin-bottom: 0;
    tr {
      th {
        background: $dark-blue;
        font-size: 15px;
        text-align: center;
        color: #fff;
        border: 0;
        font-weight: normal;
        vertical-align: middle;
        &.margin-td {
          width: 10%;
          @include media_max($md-break) {
            width: 0;
            display: none;
          }
        }
        &:not(.margin-td) {
          &:nth-of-type(2n+1) {
            //background: scale_color($light-blue, $lightness: 95%);
            background: scale_color($dark-blue, $lightness: 2%);
          }
        }
        @include media_max($sm-break) {
          font-size: 13px;
        }
      }
      td {
        text-align: center;
        vertical-align: middle;
        font-size: 15px;
        border: 0;
        border-bottom: 1px solid $dark-blue;
        white-space: normal !important;
        &.margin-td {
          @include media_max($md-break) {
            display: none;
          }
        }
        &:not(.margin-td) {
          &:nth-of-type(2n) {
            background: scale_color(#fff, $lightness: -2%);
          }
        }
        &.no-break {
          white-space: nowrap !important;
        }
        &.actions-td {
          $width: 200px;
          width: $width;
          max-width: $width;
          min-width: $width;
          padding: 15px;
          .button {
            display: block;
            font-size: 15px;
            line-height: 36px;
            height: 36px;
            margin-bottom: 5px;
            &:last-of-type {
              margin-bottom: 0;
            }
            @include media_max($md-break) {
              font-size: 13px;
            }
            @include media_max($sm-break) {
              font-size: 10px;
              line-height: 27px;
              height: 27px;
            }
          }
          @include media_max($sm-break) {
            $width: 150px;
            width: $width;
            max-width: $width;
            min-width: $width;
          }
          &.call-actions {
            $width: 300px;
            width: $width;
            max-width: $width;
            min-width: $width;
            @include media_max($sm-break) {
              $width: 150px;
              width: $width;
              max-width: $width;
              min-width: $width;
            }
          }
        }
        .user-image-block {
          display: inline-block;
          .user-image {
            max-width: 90px;
            max-height: 90px;
            display: inline-block;
            @include media_max($xs-break) {
              margin-bottom: 10px;
            }
          }
        }
        &.name-td {
          font-weight: 700;
          min-width: 100px;
          white-space: normal;
          //text-align: left;
          &.call-name {
            width: 250px;
            @include media_max($xs-break) {
              width: auto;
              min-width: 0;
            }
          }
          @include media_max($sm-break) {
            font-weight: 400;
          }
        }
        &.count-td {
          width: 250px;
          @include media_max($xs-break) {
            width: auto;
            min-width: 0;
          }
        }
        .status-xs {
          margin-bottom: 10px;
        }
        @include media_max($md-break) {
          font-size: 13px;
        }
        @include media_max($sm-break) {
          font-size: 12px;
        }
      }
      &:last-of-type {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}

//Pagination
.pagination {
  text-align: center;
  display: block;
  border-top: 1px solid $dark-blue;
  border-radius: 0;
  margin: 0;
  padding: 30px 0;
  li {
    display: inline-block;
    * {
      display: block;
      min-width: 35px;
      height: 35px;
      line-height: 24px !important;
      border-radius: 5px;
      font-size: 15px;
      color: #fff !important;
      margin: 0 3px;
      border: 0;
      background: $light-blue;
      padding: 0 5px;
    }
    span {
      background: $light-blue;
    }
    a {
      color: #fff;
      background: $light-blue;
      border: 0;
      &:hover {
        background: scale_color($dark-blue, $lightness: 15);
        text-decoration: none;
        color: #fff;
      }
    }
    &.active {
      span {
        background: $dark-blue;
        border: 0;
        &:hover {
          background: $dark-blue;
        }
      }
    }
    &.disabled {
      span {
        opacity: .3;
        background: $dark-blue;
        border: 0;
        &:hover {
          background: $dark-blue;
        }
      }
    }
    .page-arrow {
      font-size: 13px;
    }
  }
}

// Empty table or page
.empty-page-info {
  text-align: center;
  font-size: 18px;
  padding: 100px 15px;
  max-width: 500px;
  margin: 0 auto;
  &.green {
    color: $green;
  }
  &.red {
    color: $red;
  }
}

//Consult page
.search-consult-filter {
  padding: 30px 0 35px 0;
  input,
  select {
    margin-bottom: 20px;
  }
  #search-doctors {
    @include media_max($xs-break) {
      margin-bottom: 15px;
    }
  }
}

.consult-corporate-user-fields {
  padding: 30px 0 35px 0;
  .date-line {
    .row {
      margin: 0 -5px;
      & > div{
        padding: 0 5px;
      }
    }
  }
  select {
    height: 28px;
  }
}

//Chat
$dark-bg: scale_color($light-blue, $lightness: 70%);
$light-bg: scale_color($light-blue, $lightness: 90%);
#chat {
  .chat-content {
    max-height: 300px;
    overflow-y: auto;
    margin: 0 0 10px 0;
    .message {
      font-size: 10px;
    }
    .time {
      color: $dark-blue;
      display: inline-block;
      text-align: right;
    }
    p {
      font-size: 12px;
      margin: 0 0 0 3px;
      span {
        font-weight: bold;
        color: #000;
      }
    }
    .oponent {
      width: 60%;
      max-width: 90%;
      float: left;
      margin-bottom: 20px;
      padding-left: 10px;
      position: relative;
      //.abs {
      //  position: absolute;
      //  top: 6px;
      //  left: -10px;
      //  border: 6px solid transparent;
      //  border-top: 6px solid $dark-bg;
      //  border-right: 6px solid $dark-bg;
      //}
      .message-text {
        position: relative;
        background-color: $dark-bg;
        font-size: 12px;
        color: $dark-blue;
        padding: 19px;
        clear: both;
        margin-left: 0;
        overflow: auto;
      }
      span {
        font-size: 14px;
        color: $dark-blue;
        float: right;
      }
      .messagerName {
        float: left;
      }
    }
    .self {
      width: 60%;
      max-width: 90%;
      float: right;
      margin-bottom: 20px;
      padding-right: 10px;
      position: relative;
      //.abs {
      //  position: absolute;
      //  top: 6px;
      //  right: -10px;
      //  border: 6px solid transparent;
      //  border-left: 6px solid $light-bg;
      //  border-top: 6px solid $light-bg;
      //}
      .message-text {
        position: relative;
        background-color: $light-bg;
        font-size: 12px;
        color: #000;
        padding: 19px;
        overflow: auto;
      }
      span {
        font-size: 14px;
        color: $dark-blue;
      }
      .messagerName {
        display: none;
      }
    }
  }
}
.green-circle {
  color: $green;
}
.red-circle {
  color: $red;
}

//Call page
.opentok-container {
  overflow: hidden;
}

.session-page {
  margin-top: 20px;
  margin-bottom: 50px;
  .doctor-online-btns {
    margin-top: 20px;
    .duration {
      padding-top: 5px;
      font-size: 22px;
      @include media_max($xs-break) {
        padding: 0;
        text-align: center;
        margin-bottom: 10px;
      }
    }
    @include media_max($xs-break) {
      margin: 10px 0 0 0;
    }
  }
  .video-box {
    position: relative;
    width: 100%;
    #subscribers {
      width: 100%;
      height: 700px;
      background: $dark-blue;
      position: relative;
      z-index: 90;
      overflow: hidden;
      & > div {
        width: 100% !important;
        height: 100% !important;
      }
      video {
        width: 100% !important;
        height: 100% !important;
      }
      @include media_max($md-break) {
        height: 580px;
      }
      @include media_max($sm-break) {
        height: 455px;
      }
      @include media_max($xs-break) {
        height: 182px;
      }
    }
    #publisher {
      position: absolute !important;
      width: 300px !important;
      height: 182px !important;
      z-index: 100;
      border: 4px solid #fff;
      right: 25px;
      bottom: 25px;
      background: $light-blue;
      @include media_max($md-break) {
        width: 250px !important;
        height: 152px !important;
        border: 3px solid #fff;
        right: 20px;
        bottom: 20px;
      }
      @include media_max($sm-break) {
        width: 150px !important;
        height: 92px !important;
        border: 2px solid #fff;
        right: 5px;
        bottom: 5px;
      }
      @include media_max($xs-break) {
        width: 100px !important;
        height: 61px !important;
        border: 1px solid #fff;
        right: 2px;
        bottom: 2px;
      }
    }
  }
  .notes {
    margin-bottom: 40px;
    #chat {
      #chat-message {
        height: 33px;
      }
      .chat-send {
        border-radius: 0 5px 5px 0;
        min-width: 200px;
        @include media_max($sm-break) {
          min-width: 50px;
        }
      }
      .chat-content {
        border-top: 1px solid #ccc;
        background: #fafafa;
        min-height: 200px;
        max-height: 300px;
        overflow-y: auto;
        margin: 0 0 10px 0;
      }
    }
  }
  .doctor-online-textarea {
    textarea {
      margin-bottom: 15px;
    }
  }
  .caller-info-line {
    margin-bottom: 20px;
    .caller-name {
      font-size: 16px;
      display: inline-block;
      margin-right: 20px;
    }
  }
  .button-block {
    text-align: right;
    @include media_max($xs-break){
      text-align: center;
    }
  }
  .finish-btn {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 20px;
    @include media_max($xs-break){
      margin-left: 0;
    }
  }
  .session-control {
    display: inline-block;
    background: none;
    width: 32px;
    height: 32px;
    border: 0;
    margin: 0 10px 5px 10px;
    &.video-toggle {
      background: url("../images/video-toggle.png") no-repeat center top;
    }
    &.audio-toggle {
      background: url("../images/audio-toggle.png") no-repeat center top;
    }
    &.sound-toggle {
      background: url("../images/sound-toggle.png") no-repeat center top;
    }
    &.off {
      background-position: center bottom !important;
    }
  }
}

.call-popup-modal {
  #username {
    margin-bottom: 15px;
  }
  .left-block {
    padding-right: 7px;
  }
  .right-block {
    padding-left: 7px;
  }
}

// Call end page
.end-call-page {
  text-align: center;
  .loading-block {
    display: none;
    border: 0;
  }
  #rate-form {
    .loading-block {
      height: 318px;
    }
  }
  #prescription-form {
    .loading-block {
      height: 126px;
    }
  }
  .form-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title-rate {
    font-size: 22px;
    margin: 0 0 20px 0;
    @include media_max($xs-break) {
      font-size: 20px;
    }
  }
  #rate {
    display: inline-block;
    text-align: left;
    list-style: none;
    padding-left: 0;
    li {
      input[type=radio] {
        display: none;
      }
      label {
        font-size: 15px;
        padding: 5px 0 5px 45px !important;
      }
      input[type=radio] + label {
        background: url("/images/radio.png") left no-repeat;
      }
      input[type=radio]:checked + label {
        background: url("/images/radio-active.png") left no-repeat;
      }
    }
  }
}

.profile-page {
  margin-bottom: 40px;
}

//Purchase call credits page
.purchase-page {
  margin: 30px 0 40px 0;
  #purchase_tokens_tokens_count-error {
    white-space: nowrap;
  }
  .secure-img2 {
    display: none;
    margin: 0 auto;
    @include media_max($xs-break){
      display: block;
    }
  }
  .payment-icons {
    margin: 10px 0 20px 0;
    text-align: center;
    img{
      display: inline-block;
      margin: 0 3px;
    }
  }
  .payment-block {
    margin: 0 15px 20px 0;
    text-align: right;
    .secure-img {
      display: inline-block;
      @include media_max($xs-break){
        display: none;
      }
    }
    .payment-icons {
      margin: 10px 0 0 0;
      text-align: right;
      img{
        display: inline-block;
        margin: 0 0 0 3px;
      }
    }
  }
  .panel {
    overflow: hidden;
    border-color: $dark-blue;
    .panel-heading {
      background: $dark-blue;
      color: #fff;
      border: 0;
    }
    .panel-body {
      padding-top: 25px;
      @include media_max($xs-break){
        padding: 10px 0 0 0;
      }
    }
  }
  label {
    margin-bottom: 0;
    line-height: 28px;
  }
  .toggle-line {
    transition: $button-transition;
    cursor: pointer;
    i {
      top: 3px;
      float: right;
      transition: 300ms;
      &.up {
        display: none;
      }
    }
    &.on {
      transition: $button-transition;
      i {
        top: 3px;
        &.up {
          display: block;
        }
        &.down {
          display: none;
        }
      }
    }
    &:hover {
      background: rgba($dark-blue, .90);
      transition: $button-transition;
      i {
        transform: rotate(360deg);
        transition: 300ms;
      }
    }
  }
  .toggle-block {
    display: none;
  }
  .tokens-quntity-list {
    list-style: none;
    padding: 0;
    li {
      margin-bottom: 5px;
      label {
        min-height: 28px;
        @include media_max($xs-break){
          margin: 0 !important;
          line-height: 1.2 !important;
          &[for="purchase_tokens_tokens_count_0"] {
            line-height: 28px !important;
          }
        }
      }
    }
  }
}

//Restore sessioln top line
.restore-session {
  background: $light-blue;
  //padding: 15px;
  text-decoration: underline;
  border-bottom: 1px solid #fff;
  display: none;
  a {
    padding: 15px;
    display: block;
    font-size: 18px;
    text-decoration: underline;
    text-shadow: #333 1px 1px 1px;
    transition: $button-transition;
    &:hover {
      text-decoration: none;
      background: $dark-blue;
      transition: $button-transition;
    }
  }
  &:hover {
    text-decoration: none;
  }
}

//Health tracker new style
.health-tracker-page {
  label {
    font-weight: 400;
    color: $dark-blue;
    line-height: 1;
    font-size: 14px;
    margin-bottom: 7px;
    &.error {
      top: 53px !important;
      left: 15px;
    }
  }
  .day-line {
    text-align: center;
    padding: 30px 15px;
    font-size: 16px;
    text-transform: uppercase;
    a {
      color: #fff;
      text-decoration: underline;
      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .fields-line {
    padding: 40px 0;
  }
  .happiness-line {
    padding-bottom: 40px;
    label {
      font-weight: bold;
    }
  }
  .many-fields {
    padding: 40px 0 30px 0;
    .form-group {
      margin-bottom: 25px;
    }
  }
  .help-info {
    float: right;
    font-size: 13px;
    line-height: 1;
    margin-top: 2px;
  }
  .button-block {
    padding-top: 21px;
  }
  .form-control {
    height: 32px;
  }
  .nav-tabs-health {
    .tab {
      padding-top: 10px;
      padding-bottom: 10px;
      a {
        display: inline-block;
        text-transform: uppercase;
        color: $dark-blue;
        font-size: 15px;
        line-height: 28px;
        padding-left: 40px;
        text-decoration: none;
        background: url('../images/radio.png') left center no-repeat;
        transition: 400ms;
      }
      &.active,
      &:hover,
      &.activeAlways {
        a {
          background: url('../images/radio-active-dark.png') left center no-repeat;
        }
      }
    }
  }
  .tab-block,
  .tab-pane {
    overflow: auto;
    max-width: 100%;
    margin-bottom: 30px;
    //min-height: 570px;
    canvas {
      //min-height: 570px;
      //min-width: 100%;
    }
  }
  //.tab-content.loading > .tab-pane {
  //  display: block !important;
  //  max-width: 100%;
  //  margin-bottom: 30px;
  //}
}

.legend {
  li {
    display: inline-block;
    margin-right: 20px;
    .legend-color {
      float: left;
      width: 20px;
      height: 20px;
      margin: 0 5px 0 0;
    }
    &:last-of-type {
      float: left;
    }
  }
}

.calendar {
  background: url("../images/calendar-ico.png") top right no-repeat scroll;
  padding-right: 40px;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    transition: 500ms;
    opacity: .8;
  }
}

//Prescriptions
.prescription-list {
  @include clearfix();
  margin-bottom: 80px;
  .block-header {
    //border-top: 1px solid $dark-blue;
    background: $light-blue;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    padding: 15px;
    text-shadow: #333 1px 1px 1px;
    text-align: center;
    .ajax-paginator {
      border: 0;
      display: none;
      float: right;
      margin-left: -180px;
      text-align: right;
      li {
        //padding-left: 5px;
        display: inline-block;
        float: none;
        .counter {
          border: 1px solid scale_color($dark-blue, $lightness: 10%);
          padding: 0 5px;
          background: scale_color($dark-blue, $lightness: 10%);
          color: #fff;
          height: 24px;
          font-size: 14px;
          border-radius: 3px;
          //margin-top: 3px;
          display: inline-block;
          line-height: 1;
        }
        button {
          height: 24px;
          background: $dark-blue;
          color: #fff;
          font-size: 14px;
          line-height: 1;
          border: 1px solid scale_color($dark-blue, $lightness: 10%);
          box-shadow: rgba(#000, .3) 1px 1px 5px 1px;
          transition: 500ms;
          border-radius: 3px;
          &:hover {
            background: scale_color($dark-blue, $lightness: 15%);
            border: 1px solid scale_color($dark-blue, $lightness: 20%);
            transition: 500ms;
          }
          &:disabled {
            transition: 500ms;
            opacity: 1;
            background: #ccc;
            border: 1px solid #d4d4d4;
            color: rgba(#000, .5);
            box-shadow: none;
          }
        }
      }
      @include media_max($xs-break) {
        float: none;
        margin: 5px 0 0 0;
        text-align: center;
      }
    }
  }
  select {
    max-width: 250px;
    margin: 0 auto;
  }
  .send-prescription {
    margin-bottom: 5px !important;
  }
  .table-page {
    tr {
      border-bottom: 1px solid $dark-blue !important;
    }
  }
  .empty-page-info {
    padding: 60px 0;
    border-bottom: 1px solid $dark-blue !important;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

.loading-block {
  background: url("../images/loader.gif") no-repeat center;
  height: 200px;
  border-bottom: 1px solid $dark-blue;
}

.loader {
  background: url("../images/loader.gif") no-repeat center;
  height: 200px;
  display: none;
}
.loader-small {
  background: url("../images/loader-small.gif") no-repeat center;
  height: 28px;
  width: 100%;
  display: none;
}
#loader-locker {
  background: rgba(#000, .3) url("../images/loader-locker.gif") no-repeat center;
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.red-border {
  border-color: $red;
}

.checkout-page {
  .loading-block {
    border: 0;
    height: 300px;
  }
}

//Pharmacy
.pharmacy-list-title {
  margin-bottom: 8px !important;
}
ul.pharmacy-list {
  list-style: none;
  border-top: 1px solid $dark-blue;
  padding: 10px 0 7px 0;
  margin: 2px 0 0 0;
  .row-label {
    label {
      padding-top: 9px !important;
    }
  }
}
.pharmacy-remove-modal,
#doctor-availability-modal {
  .empty-page-info {
    padding: 60px 30px;
  }
}

.doctor-profile-buttons {
  margin-top: 40px !important;
  padding: 0 !important;
  .button {
    &:first-of-type {
      margin-bottom: 10px;
    }
    @include media_max($xs-break){
      float: none !important;
    }
  }
  @include media_max($xs-break){
    text-align: center;
  }
}

.agreement-block {
  display: none;
  .text {
    font-size: 13px;
    line-height: 1.4;
    padding: 15px 20px;
    border: 1px solid $dark-blue;
    max-height: 500px;
    overflow: auto;
    background: $gray-bg;
    margin-bottom: 15px;
  }
  .agree-line {
    input[type=checkbox] {
      line-height: 1;
      display: inline-block;
      margin: 0 10px 0 0;
      position: relative;
      top: 2px;
      font-size: 20px;
    }
    label {
      line-height: 1;
      display: inline-block;
      height: auto;
    }
  }
}
.submit-agreement-btn {
  display: none;
}

table {
  &.file-table,
  &.MultiFile-list {
    border: 0;
    float: right;
    border-collapse: collapse;
    border-spacing: 0;
    td {
      padding: 2px 5px 2px 0;
      vertical-align: top;
      line-height: 1.1;
      &:last-of-type {
        padding: 0;
      }
    }
  }
}
.reg-step-4 {
  table {
    &.file-table,
    &.MultiFile-list {
      float: left;
      text-align: left;
    }
  }
}

.prescription-dispensing {
  .message {
    font-size: 20px;
    color: $green;
    text-align: center;
    padding: 70px 15px 50px 15px;
    @include media_max($xs-break){
      font-size: 17px;
    }
  }
  .title {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    @include media_max($xs-break){
      font-size: 17px;
    }
  }
  .title2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 50px;
    @include media_max($xs-break){
      font-size: 17px;
    }
  }
  .table-view {
    .data-profile {
      max-width: 450px;
      padding: 0 15px;
      form {
        margin-bottom: 50px;
      }
      label {
        line-height: 28px;
        float: right;
        @include media_max($xs-break){
          //float: none;
          font-size: 13px;
          line-height: 28px;
        }
      }
    }
  }
}

.doctors-filter {
  padding: 30px 0 15px 0;
  button {
    line-height: 28px;
    height: 28px;
  }
}
#doctor-list-monitoring {
  #doctor-list-table {
    button {
      margin-bottom: 5px;
    }
  }
}
#doctor-status-modal {
  .empty-page-info {
    padding: 50px 15px;
  }
}
.backend-doctor-view {
  .button-line {
    padding: 40px 0;
    max-width: 600px;
    margin: 0 auto;
    button {
      float:left;
    }
    a {
      float:right;
    }
  }
}

.help-info {
  font-size: 11px;
  line-height: 1;
}

.alert {
  .error {
    position: relative !important;
    margin: 5px 0 0 0 !important;
    display: block !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
  }
}

//Browser list
.browser-list {
  text-align: center;
  margin-bottom: 70px;
  a {
    display: inline-block;
    width: 120px;
    margin: 0 10px 20px 10px;
    color: $dark-blue;
    text-decoration: underline;
    span {
      display: block;
      padding-top: 5px;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

//Queue page
#queue-list {
  .CountDownTimer {
    width: 80px;
    height: 80px;
    margin: 0 auto;
  }
  .time_circles {
    position: relative;
    width: 100%;
    height: 100%;
    .time-counter {
      text-align: center;
      line-height: 24px;
    }
    & > div {
      position: absolute;
      text-align: center;
    }
    h4, span {
      display: none;
    }
  }
}
#profile-modal {
  .corporate-block,
  .charity-block,
  .patient-block {
    display: none;
  }
  .loader {
    display: block;
  }
}
#profile-modal {
  .zebra-rows {
    .form-group {
      margin-bottom: 0;
      padding-bottom: 10px;
      padding-top: 10px;
      &:not(:last-of-type) {
        border-bottom: 1px dashed #000;
      }
    }
  }
}

#alert {
  .message {
    text-align: center;
    font-size: 16px;
  }
}