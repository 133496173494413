$dark-blue: #002E40;
$light-blue: #00B7FF;
$button-transition: 800ms;
$gray-bg: #F9F9F9;
$md-break: 1199;
$sm-break: 1023;
$xs-break: 767;
$xxs-break: 567;
$green: #5cb85c;
$red: #d9534f;
$gray: #464646;
$footer-height: 381px;