@mixin media_min($query) {
  @media (min-width: #{$query}px) {
    @content;
  }
}

@mixin media_max($query) {
  @media (max-width: #{$query}px) {
    @content;
  }
}

@mixin media_min_max($min, $max) {
  @media (min-width: #{$min}px) and (max-width: #{$max}px) {
    @content;
  }
}
