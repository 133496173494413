.contact-form {
  max-width: 500px;
  margin: 20px auto 0 auto;
}

//Video background
.video-wrap {
  background: url("../images/video-bg.jpg") no-repeat center bottom;
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 554px;
  #video-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    @include media_max($md-break) {
      display: none;
    }
  }
  .video-front-block {
    position: relative;
    z-index: 2;
  }
  &.front-page {
    height: 302px;
    .title-cont {
      height: 300px;
      @include media_max($md-break) {
        height: 200px;
      }
      @include media_max($xs-break) {
        height: 150px;
      }
    }
    @include media_max($md-break) {
      height: 200px;
    }
    @include media_max($xs-break) {
      height: 150px;
    }
  }
  @include media_max($xs-break) {
    height: 502px;
  }
}

//Slider
@import "slick";
.slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  li {
    width: 20px;
    height: 20px;
    display: inline-block;
    button {
      text-indent: 100px;
      overflow: hidden;
      border: 0;
      background: $dark-blue;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    &.slick-active {
      button {
        background: $light-blue;
      }
    }
  }
}

.slider-wrap {
  padding: 80px 0 30px 0;
}

.top-slider {
  max-width: 400px;
  margin: 0 auto;
  .slick-dots {
    margin-top: 30px;
  }
  .item {
    font-size: 10px;
    padding: 0 15px;
    .wrap {
      margin: 0 auto;
      width: 340px;
      background: rgba($dark-blue, .5);
      color: #fff;
      padding: 50px 28px 40px 28px;
      text-align: center;
      .title {
        text-align: left;
        font-size: 2.3em;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: 10px;
        height: 46px;
      }
      .text {
        text-align: left;
        font-size: 1.82em;
        line-height: 1.4;
        margin-bottom: 30px;
      }
      .icons {
        margin-bottom: 30px;
        height: 36px;
        .icon {
          display: inline-block;
          margin: 0 7px;
          &.icon-apple {
            width: 25px;
            height: 34px;
            background: url("../images/slider-icon-apple.png") no-repeat center;
          }
          &.icon-android {
            width: 28px;
            height: 34px;
            background: url("../images/slider-icon-android.png") no-repeat center;
          }
          &.icon-windows {
            width: 32px;
            height: 34px;
            background: url("../images/slider-icon-windows.png") no-repeat center;
          }
          &.icon-apple-tv {
            width: 50px;
            height: 34px;
            background: url("../images/slider-icon-apple-tv.png") no-repeat center;
          }
          &.icon-appstore {
            width: 121px;
            height: 36px;
            background: url("../images/slider-icon-appstore.png") no-repeat center;
            @include media_max($xs-break) {
              width: 115px;
              background-size: contain;
              margin: 0 6px;
            }
          }
          &.icon-gp {
            width: 121px;
            height: 36px;
            background: url("../images/slider-icon-gp.png") no-repeat center;
            @include media_max($xs-break) {
              width: 115px;
              background-size: contain;
              margin: 0 6px;
            }
          }
          &:hover {
            opacity: .8;
          }
        }

      }
      @include media_max($xs-break) {
        width: 300px;
        padding: 30px 20px 20px 20px;
      }
    }
    @include media_max($xs-break) {
      font-size: 9px;
      padding: 0 10px;
    }
  }
}

//Home default block
.home-block {
  padding: 50px 0 70px 0;
  .block-title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 60px;
    @include media_max($xs-break) {
      font-size: 18px;
      margin-bottom: 40px;
    }
  }
  .block-title-sub {
    text-align: center;
    font-size: 18px;
    //font-weight: 700;
    line-height: 1.2;
    margin-top: -20px;
    margin-bottom: 40px;
    @include media_max($xs-break) {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
  &.blue-bg {
    background: $dark-blue;
    .block-title,
    .block-title-sub {
      color: #fff;
    }
  }
}

//Instant access line
.instant-line {
  background: $light-blue;
  padding: 22px 0;
  .container {
    max-width: 460px;
    text-align: center;
    table {
      display: inline-block;
      td {
        color: #fff;
        font-size: 19px;
        line-height: 24px;
        &.first-td {
          text-align: right;
        }
        &.last-td {
          text-align: left;
        }
        &.img-td {
          padding: 0 25px;
          @include media_max($xxs-break) {
            padding: 0 10px;
            max-width: 65px;
          }
        }
        @include media_max($xxs-break) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

//WHO IS DOC FOR
.who-is-doc-for-block {
  text-align: center;
  .more-info {
    display: none;
  }
  .item-wrap {
    padding: 100px 4px 0 4px;
    .item {
      $offset: 228px / 2;
      background: $dark-blue;
      border-bottom: 10px solid $light-blue;
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      position: relative;
      padding: $offset+20 10px 30px 10px;
      max-width: 317px;
      margin: 0 auto 50px auto;
      box-shadow: 0 2px 2px 0 #555;
      .img-wrap {
        width: 80%;
        background: #fff;
        border-radius: 50%;
        padding: 8px;
        top: -$offset;
        left: 50%;
        margin-left: -$offset;
        position: absolute;
        .img-block {
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0 2px 3px 0 #888;
          img {
            width: 100%;
          }
        }
      }
      @include media_max($md-break) {
        $offset: 188px / 2;
        padding: $offset+20 10px 30px 10px;
        .img-wrap {
          top: -$offset;
          margin-left: -$offset;
        }
      }
      @include media_max($sm-break) {
        font-size: 14px;
        line-height: 20px;
        $offset: 144px / 2;
        padding: $offset+20 10px 30px 10px;
        .img-wrap {
          top: -$offset;
          margin-left: -$offset;
        }
      }
      @include media_max($xs-break) {
        font-size: 18px;
        line-height: 22px;
        $offset: 250px / 2;
        padding: $offset+20 10px 30px 10px;
        .img-wrap {
          top: -$offset;
          margin-left: -$offset;
        }
      }
    }
  }
}

.popover-title {
  background: $dark-blue;
  color: #fff;
}

// HOW DOC WORKS
.how-doc-works-block {
  .block-title {
    margin-bottom: 90px;
    @include media_max($sm-break) {
      margin-bottom: 40px;
    }
  }
  .button {
    margin-top: 20px;
  }
  .how-doc-works-slider {
    width: 245px;
    height: 433px;
    margin: 72px 0 0 18px;
    .item {
      width: 245px;
      height: 433px;
      @for $i from 1 through 3 {
        &.how-doc-works-#{$i} {
          background: url("../images/how-doc-works-#{$i}.jpg") no-repeat center;
          background-size: cover;
        }
      }
    }
    .slick-dots {
      margin-top: -40px;
      position: relative;
      z-index: 3;
    }
  }
  .list-wrap {
    position: relative;
    text-align: left;
    .image-block {
      background: url("../images/iphone.png") no-repeat center;
      width: 285px;
      height: 577px;
      position: absolute;
      right: 70px;
      top: -45px;
      @include media_max($md-break) {
        right: 40px;
      }
    }
  }
  .list {
    list-style: none;
    padding: 0;
    .item {
      padding: 30px 40px 30px 40px;
      margin-bottom: 22px;
      background: #fff;
      box-shadow: 0 2px 3px 0 #999;
      .count-td {
        $width: 130px;
        width: $width;
        max-width: $width;
        padding-right: 45px;
        .count {
          background: $light-blue;
          border-radius: 50%;
          height: 85px;
          line-height: 85px;
          font-size: 41px;
          color: #fff;
          text-align: center;
          @include media_max($xs-break) {
            height: 45px;
            line-height: 45px;
            font-size: 25px;
          }
        }
        @include media_max($md-break) {
          $width: 110px;
          width: $width;
          max-width: $width;
          padding-right: 25px;
        }
        @include media_max($xs-break) {
          $width: 60px;
          width: $width;
          max-width: $width;
          padding-right: 15px;
        }
      }
      .text-td {
        font-size: 16px;
        line-height: 19px;
        max-width: 470px;
        .page-text-title {
          margin-bottom: 10px;
          line-height: 1;
          font-size: 22px;
        }
        @include media_max($md-break) {
          max-width: 400px;
        }
        @include media_max($sm-break) {
          max-width: 100%;
        }
        @include media_max($xs-break) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      @include media_max($xs-break) {
        padding: 20px 15px 20px 20px;
      }
    }
  }
  @include media_max($xs-break) {
    text-align: center;
  }
}

// UNIQUE FEATURES
.unique-features-block {
  text-align: center;
  .unique-features-slider {
    margin-bottom: 50px;
    .item {
      margin-bottom: 35px;
      .image-block {
        padding-right: 65px;
        img {
          width: 100%;
        }
      }
      .text-block {
        text-align: left;
        .text-wrap {
          display: table-cell;
          height: 343px;
          vertical-align: middle;
          @include media_max($md-break) {
            height: auto;
          }
          @include media_max($xs-break) {
            text-align: center;
          }
        }
        .text-title {
          font-size: 20px;
          line-height: 1.2;
          margin-bottom: 25px;
          text-transform: uppercase;
          font-weight: bold;
        }
        .text {
          text-align: left;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .icons {
    .icon {
      display: inline-block;
      margin: 22px 6px 0 6px;
      &.icon-appstore {
        width: 121px;
        height: 36px;
        background: url("../images/slider-icon-appstore.png") no-repeat center;
      }
      &.icon-gp {
        width: 121px;
        height: 36px;
        background: url("../images/slider-icon-gp.png") no-repeat center;
      }
      &:hover {
        opacity: .8;
      }
    }
  }
}

//EXPERIENCED DOCTORS
.experienced-doctors-block {
  .text-block {
    padding-top: 20px;
    .text-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 25px;
      @include media_max($md-break) {
        margin-bottom: 15px;
      }
      @include media_max($xs-break) {
        font-size: 18px;
      }
    }
    .text {
      font-size: 16px;
      line-height: 1.8;
      margin-bottom: 30px;
      @include media_max($md-break) {
        margin-bottom: 20px;
        line-height: 1.6;
      }
      @include media_max($sm-break) {
        font-size: 14px;
        line-height: 1.5;
      }
      @include media_max($xs-break) {
        text-align: left;
      }
    }
    @include media_max($md-break) {
      padding-top: 0;
    }
    @include media_max($xs-break) {
      text-align: center;
    }
  }
  .image-block {
    img {
      max-height: 300px;
      width: auto;
    }
  }
}

//PRICING
.pricing {
  text-align: center;
  color: #fff;
  font-size: 10px;
  .block-title {
    margin-bottom: 20px;
  }
  &__line {
    @include clearfix();
    display: inline-block;
    width: 50px;
    border-bottom: 1px solid rgba(#fff, .5);
    margin: 17px 0 10px 0;
    opacity: .5;
  }
  &__text {
    font-size: 1.8em;
    font-weight: 300;
    &--small {
      font-weight: 300;
      font-size: 1.5em;
    }
  }
  &__item-row {
    padding-top: 60px;
    padding-bottom: 60px;
    @include media_max($xs-break) {
      padding-bottom: 30px;
    }
  }
  &__item {
    background: $light-blue;
    border-radius: 4px;
    line-height: 1;
    margin: 0 15px;
    @include media_max($md-break) {
      margin: 0;
    }
    @include media_max($xs-break) {
      margin-bottom: 30px;
    }
  }
  &__holder {
    padding: 28px 15px 20px 15px;
    border-bottom: 1px solid #33c5ff;
    height: 175px;
    @include media_max($sm-break) {
      height: 145px;
    }
    @include media_max($xs-break) {
      padding: 18px 15px 10px 15px;
      height: 115px;
    }
  }
  &__title {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 30px;
    @include media_max($sm-break) {
      margin-bottom: 20px;
    }
    @include media_max($xs-break) {
      margin-bottom: 16px;
    }
  }
  &__number {
    font-size: 4em;
    font-weight: 400;
    margin-bottom: 20px;
    @include media_max($xs-break) {
      margin-bottom: 15px;
    }
  }
  &__each {
    font-size: 1.5em;
    font-weight: 300;
  }
  &__wrap {
    @include clearfix();
    font-size: 2em;
    font-weight: 300;
    padding: 25px 40px 20px 40px;
    text-align: left;
    @include media_max($xs-break) {
      padding: 15px 20px;
    }
  }
  &__price {
    font-size: 1.1em;
    margin-top: -2px;
    float: right;
    @include media_max($sm-break) {
      margin: 0;
    }
  }
  &__button {
    background: $dark-blue;
    font-weight: 400;
    font-size: 1.4em;
    border-radius: 14px;
    padding: 9px 35px;
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
    transition: $button-transition;
    &:hover {
      color: #fff;
      text-decoration: none;
      background: scale_color($dark-blue, $lightness: 10%);
      transition: $button-transition;
    }
    @include media_max($sm-break) {
      padding: 8px 20px;
    }
    @include media_max($xs-break) {
      margin-bottom: 15px;
    }
  }
  &__info-line {
    border-bottom: 1px solid rgba(#fff, .1);
    margin: 0 auto -13px auto;
    width: 80%;
    @include media_max($sm-break) {
      margin-bottom: -10px;
    }
    @include media_max($xs-break) {
      margin-bottom: -8px;
      width: 90%;
    }
  }
  &__info {
    font-size: 1.8em;
    margin: 0 auto;
    background: $dark-blue;
    width: 30%;
    @include media_max($xs-break) {
      width: 60%;
    }
  }
  @include media_max($sm-break) {
    font-size: 7px;
  }
  @include media_max($xs-break) {
    font-size: 6px;
  }
}
@-moz-document url-prefix() {
  .pricing__button {
    padding: 10px 35px 8px 35px;
  }
}

//OFFICIAL PARTNER WCFC
.official-partner-block {
  text-align: center;
  .title-line {
    display: inline-block;
    margin-bottom: 40px;
    td {
      color: $dark-blue;
      &.img-td {
        padding: 0 20px;
        img {
          max-width: 63px;
          height: auto;
          @include media_max($xs-break) {
            max-width: 50px;
          }
        }
        @include media_max($xs-break) {
          padding: 0 10px;
        }
      }
      &.first-td {
        font-size: 22px;
        @include media_max($xs-break) {
          font-size: 14px;
        }
      }
      &.last-td {
        font-size: 25px;
        color: #494949;
        @include media_max($xs-break) {
          font-size: 15px;
        }
      }
    }
    @include media_max($xs-break) {
      margin-bottom: 5px;
    }
  }
  .block-title-xs {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .text-block {
    text-align: left;
    padding-top: 10px;
    padding-right: 35px;
    .text {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
      @include media_max($xs-break) {
        text-align: left;
      }
    }
    @include media_max($md-break) {
      padding-top: 0;
    }
    @include media_max($xs-break) {
      padding-right: 15px;
      padding-top: 0;
      text-align: center;
    }
  }
  .image-block {
    .text-bg {
      @include clearfix();
      background: url("../images/wcfc/home_1.jpg") no-repeat center;
      background-size: cover;
      position: relative;
      text-align: center;
      .text {
        background: rgba(#231F20, .8);
        color: #fff;
        font-size: 22px;
        line-height: 26px;
        max-width: 270px;
        padding: 35px 20px;
        float: left;
        margin: 60px 0 60px 15px;
        border-radius: 4px;
        text-transform: uppercase;
        @include media_max($sm-break) {
          margin: 80px 0;
          display: inline-block;
          float: none;
        }
      }
    }
  }
}

//Marketing content pages
.experienced-page {
  .wcfc-block {
    img {
      max-width: 220px;
    }
  }
}

.how-it-works-steps {
  .item {
    //padding: 0 30px;
    font-size: 16px;
    .item-wrap {
      background: $dark-blue;
      color: #fff;
      box-shadow: 0 2px 2px 0 #555;
      border-bottom: 5px solid $light-blue;
      //border-radius: 10px 10px 0 0;
      overflow: hidden;
      .title {
        background: $light-blue;
        font-size: 26px;
        padding: 10px;
        color: #fff;
        font-weight: 400;
        text-align: center;
      }
      .text {
        padding: 15px 25px;
        text-shadow: #000 1px 1px;
        display: table-cell;
        vertical-align: top;
        height: 200px;
        font-size: 16px;
        @include media_max($md-break) {
          height: 190px;
          font-size: 14px;
        }
        @include media_max($sm-break) {
          height: 190px;
          font-size: 13px;
          padding: 10px;
        }
        @include media_max($xs-break) {
          height: auto;
          font-size: 15px;
          padding: 15px 25px;
          text-align: left;
          .page-text-title {
            text-align: left;
          }
        }
      }
    }
  }
}

.what-we-treat-block {
  img {
    margin-top: 45px;
  }
}

.blue-box {
  .blue-box-item {
    @include clearfix();
    color: #fff;
    padding-right: 200px;
    .text-block {
      //width: 670px;
      position: relative;
      background: $dark-blue;
      padding: 20px 220px 20px 40px;
      border-bottom: 10px solid $light-blue;
      box-shadow: 0 2px 2px 0 #555;
      text-align: left;
      .vertical-wrap {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        height: 220px;
        text-shadow: #000 1px 1px;
        @include media_max($xs-break) {
          height: auto;
        }
      }
      .page-text-title {
        color: $light-blue;
        @include media_max($xxs-break) {
          margin-bottom: 10px;
        }
      }
      .image-block {
        position: absolute;
        right: -200px;
        top: 50%;
        margin-top: -200px;
        background: #fff;
        border-radius: 50%;
        padding: 8px;
        width: 400px;
        height: 400px;
        overflow: hidden;
        img {
          border-radius: 50%;
          width: 100%;
          box-shadow: 0 2px 3px 0 #888;
        }
        @include media_max($sm-break) {
          width: 300px;
          height: 300px;
          right: -160px;
          margin-top: -150px;
        }
      }
      ul {
        font-size: 15px;
      }
      @include media_max($sm-break) {
        width: 570px;
        padding-right: 170px;
      }
      @include media_max($xs-break) {
        width: 100%;
        padding-right: 40px;
      }
      @include media_max($xxs-break) {
        padding: 20px;
      }
    }
    @include media_max($sm-break) {
      padding-right: 150px;
      padding-left: 0;
    }
    @include media_max($xs-break) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  &.content-block {
    padding: 100px 0;
    @include media_max($xs-break) {
      padding: 50px 0;
    }
  }
  &.right-position {
    text-align: right;
    .blue-box-item {
      padding-left: 200px;
      padding-right: 0;
      .text-block {
        //margin-left: 200px;
        padding: 20px 40px 20px 235px;
        .image-block {
          right: auto;
          left: -200px;
          @include media_max($sm-break) {
            left: -160px;
          }
        }
        @include media_max($sm-break) {
          padding: 20px 40px 20px 180px;
        }
        @include media_max($xs-break) {
          padding-left: 40px;
        }
        @include media_max($xxs-break) {
          padding: 20px;
        }
      }
      @include media_max($sm-break) {
        padding-left: 150px;
        padding-right: 0;
      }
      @include media_max($xs-break) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @include media_max($xs-break) {
      text-align: center;
    }
  }
  &.gray-bg {
    .text-block {
      .image-block {
        background: $gray-bg;
      }
    }
  }
  .button {
    margin-bottom: -50px;
  }
}

//Team
.team-page {
  .container {
    max-width: 800px;
  }
  .team-item {
    .img-block {
      text-align: center;
      img {
        border-radius: 50%;
        max-width: 290px;
        @include media_max($xs-break) {
          margin-bottom: 20px;
        }
      }
    }
    .name {
      font-size: 30px;
      line-height: 1;
      margin-bottom: 5px;
      @include media_max($xs-break) {
        text-align: center;
        font-size: 26px;
      }
    }
    .job {
      font-size: 20px;
      margin-bottom: 5px;
      @include media_max($xs-break) {
        text-align: center;
        font-size: 18px;
      }
    }
    .text {
      font-size: 15px;
    }
  }
}

//Features block
.features-block {
  .container {
    max-width: 500px;
    text-align: center;
  }
  .table-responsive {
    border: 0;
    margin-bottom: 40px;
  }
  table {
    tr {
      td {
        border-bottom: 1px solid #ddd;
        &:nth-of-type(2n) {
          text-align: left;
          font-size: 18px;
        }
        &:first-of-type {
          text-align: center;
          width: 55px;
          padding-right: 0;
        }
      }
      &:nth-of-type(2n) {
        td {
          background: $gray-bg;
        }
      }
    }
  }
}